import { ComponentInstance } from "@vue/composition-api";
import { getHiddenTableFields } from "@monorepo/utils/src/utils/getHiddenTableFields";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import axios from "axios";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";

const useSetStartCells = async (
  root: ComponentInstance,
  moduleName: string,
  section: string,
  tableHeaders: { isHiddenByDefault?: boolean; value: string }[],
  isNeedAutorefresh = false
): Promise<void> => {
  const store = root.$store;

  try {
    if (!store.getters[`${moduleName}/cells`].length) {
      let data = await store.dispatch(`${moduleName}/getCells`, section);
      if (!data) {
        await axios.post(`${getFullPath(QUERY_PATH.GET_COLL_STATE)}`, {
          autorefresh: isNeedAutorefresh,
          showFilter: false,
          columnStates: getHiddenTableFields(tableHeaders),
          section: section,
          username: store.getters["auth/user"].name,
        });
        data = await store.dispatch(`${moduleName}/getCells`, section);
      }
      store.commit(`${moduleName}/setCells`, data?.columnStates ?? getHiddenTableFields(tableHeaders));
      store.commit(`${moduleName}/setIsOpenFilters`, data?.showFilter ?? false);
      await store.commit(`${moduleName}/setAutorefresh`, data?.autorefresh ?? false);
      store.commit(`${moduleName}/setSectionCells`, data ?? {});
    }
  } catch (e) {
    console.error(e);
    store.commit(`${moduleName}/setCells`, getHiddenTableFields(tableHeaders));
  }
};

export default useSetStartCells;
