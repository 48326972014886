





















import { defineComponent } from "@vue/composition-api";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { GeneratedIntervalTypes } from "@monorepo/utils/src/types/IScheduledReport";
import { mapGetters } from "vuex";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";

const exportTypes = [
  {
    title: "Выключена",
    value: "off",
  },
  {
    title: "Ежедневно",
    value: GeneratedIntervalTypes.DAILY,
  },
  {
    title: "Ежемесячно",
    value: GeneratedIntervalTypes.MONTHLY,
  },
  {
    title: "Ежегодно",
    value: GeneratedIntervalTypes.YEARLY,
  },
];

export default defineComponent({
  name: "FiltersAutoExport",
  props: {
    exceptItems: {
      type: Array,
      default: () => [GeneratedIntervalTypes.YEARLY],
    },
    sectionName: {
      type: String,
      default: "",
    },
    views: {
      type: Object,
      required: true,
    },
    currentTemplateId: {
      type: [String, Number],
    },
  },
  methods: {
    async update(item: { title: string; value: string }) {
      let updatedTemplate = {};
      if (item.value === "off") {
        updatedTemplate = {
          ...this.template.scheduledReport,
          active: false,
        };
        return await this.$store.dispatch(`${this.sectionName}/updateScheduledReport`, updatedTemplate);
      }
      updatedTemplate = {
        ...this.template.scheduledReport,
        generateInterval: item.value,
        active: true,
      };
      return await this.$store.dispatch(`${this.sectionName}/updateScheduledReport`, updatedTemplate);
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    exportTypesResult(): {
      title: string;
      value: string;
    }[] {
      return exportTypes.filter((item) => !this.exceptItems.includes(item.value));
    },
    isCanModify(): boolean {
      return isAuthorityExist(this.user, authorities.SCHEDULED_REPORT_MODIFY);
    },
    template(): ISearchTemplate {
      return this.$store.getters[`${this.sectionName}/searchTemplates`].find((item: ISearchTemplate) => item.id === this.currentTemplateId);
    },
    isShownIcon(): (item: { title: string; value: string }) => boolean {
      return (item) => {
        if (this.template.scheduledReport?.active) {
          return this.template.scheduledReport.generateInterval === item.value;
        }
        return item.value === "off";
      };
    },
  },
});
