































import { defineComponent, PropType } from "@vue/composition-api";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";

type tableHeaders = { value: string; isActive?: boolean; [key: string]: unknown };
export default defineComponent({
  name: "ToggleTableCells",
  data() {
    return {
      educationIds,
    };
  },
  props: {
    cells: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    tableHeaders: {
      type: Array as PropType<tableHeaders[]>,
      default: () => [],
    },
  },
  computed: {
    items(): tableHeaders[] {
      const checkedItems = this.tableHeaders.filter((item) => item.value !== "checkbox");
      if (this.cells.length) {
        return checkedItems.map((item) => {
          return {
            ...item,
            isActive: this.cells.includes(item.value),
          };
        });
      }

      return checkedItems.map((item) => ({ ...item, isActive: true }));
    },
  },
  methods: {
    icon(name: string): string {
      return `${environmentVariables.BASE_URL}assets/images/monitoring/${name}.svg`;
    },
    clearCell() {
      this.$emit(
        "setCells",
        this.tableHeaders?.filter((item) => !item.isHiddenByDefault).map((item) => item.value)
      );
    },
    toggleCell(item: tableHeaders) {
      let resultValues: string[] = (this.cells as string[]) || [];
      if (resultValues.length === 1 && resultValues.includes(item.value)) {
        return;
      }
      if (resultValues.length) {
        resultValues = resultValues.includes(item.value) ? resultValues.filter((key) => key !== item.value) : resultValues.concat(item.value);
      } else {
        resultValues = this.tableHeaders.filter((el) => el.value !== item.value).map((item) => item.value);
      }

      this.$emit("setCells", resultValues);
    },
  },
});
