import { computed, ComputedRef, onMounted, onUnmounted, ref, Ref, SetupContext, watch } from "@vue/composition-api";
import Vue from "vue";
import { debounce, isEmpty } from "lodash";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";

interface IUseFilter {
  filter: ComputedRef<(id: string) => { value: string; title: string }>;
  hiddenRowsStyleObj: ComputedRef<Record<string, string | undefined>>;
  openHiddenFilterOnStart: () => void;
  toggleOpenFilter: () => void;
  clearFiltersCb: () => void;
  clearFiltersNewCb: () => void;
  refresh: () => void;
  updateFiltersDivHeight: () => void;
  restoreFiltersFromStore: (isDefault: boolean) => void;
}

const useFilter = (
  context: SetupContext,
  props: {
    value: boolean;
    currentDocumentView?: ViewMode;
  },
  moduleName: string,
  customFieldNames: {
    clearFilters?: string;
    refreshScroll?: string;
    refreshReport?: string;
    addFilter?: string;
    fieldFilters?: string;
  },
  currentFilters: Ref<Record<string, unknown>>,
  setCurrentFilters: () => Record<string, unknown>,
  filtersElements: { value: string; title: string }[],
  notHiddenKeys: Ref<string[]>,
  isShowToggle: Ref<boolean>,
  isHasTopTable = false,
  isReportForm = false,
  isShowReportToggle = false,
  filtersDivHeight = ref(0),
  defaultFiltersDivHeight = 88,
  getQueryCb?: () => void,
  changeCurrentFiltersBeforeAdd?: (currentFilter: any) => void
): IUseFilter => {
  const { root } = context;
  const store = root.$store;
  const route = computed(() => root.$route || {});
  const notHiddenElements = ref([] as HTMLElement[]);
  const hiddenKeys = ref([] as string[]);

  const fieldFilters = computed(() => store.getters[`${moduleName}/${customFieldNames?.fieldFilters || "fieldFilters"}`]);
  const filter = computed(() => {
    return (id: string) => {
      return filtersElements.find((item: { value: string; title: string }) => item.value === id) || { value: "", title: "" };
    };
  });
  const hiddenRowsStyleObj = computed(() => {
    const resultHeight = filtersDivHeight.value > defaultFiltersDivHeight ? filtersDivHeight.value : defaultFiltersDivHeight;
    return isReportForm && !isShowReportToggle
      ? {}
      : {
          height: (isShowReportToggle && !isShowToggle.value) || (props.value && hiddenKeys.value.length) ? "" : `${resultHeight}px !important`,
          overflow: `hidden`,
        };
  });
  const refreshReportKeys = computed(
    () =>
      ((filtersElements as Record<string, unknown>[])
        ?.filter((item) => (typeof item?.isRequired === "boolean" ? item.isRequired !== false : true))
        ?.reduce((acc: string[], item: Record<string, unknown>) => {
          acc = acc.concat((item.dateValues || [item.value]) as string[]);
          return acc;
        }, []) || []) as string[]
  );

  const clearFilters = () => {
    store.dispatch(`${moduleName}/${customFieldNames?.clearFilters || "clearFilters"}`);
  };
  const refreshScroll = () => {
    store.dispatch(`${moduleName}/${customFieldNames?.refreshScroll || "refreshScroll"}`);
  };
  const clearEventList = () => {
    store.dispatch(`${moduleName}/clearEventList`);
  };
  const clearReportFilters = () => {
    currentFilters.value = setCurrentFilters();
    addFilter(currentFilters.value as Record<string, SORT_TYPE>);
    if (isShowReportToggle) {
      context.emit("change", true);
      isShowToggle.value = false;
    }
    clearEventList();
  };
  const getEventTopList = () => {
    store.dispatch(`${moduleName}/getEventTopList`);
  };
  const refreshReport = () => {
    if (refreshReportKeys.value.some((key) => isEmpty(currentFilters.value[key]))) {
      showNotification("Заполните все фильтры для формирования отчета");
      return;
    }
    changeCurrentFiltersBeforeAdd?.(currentFilters.value);
    addFilter(currentFilters.value as Record<string, SORT_TYPE>);
    if (isHasTopTable) {
      getEventTopList();
    }
    refreshScroll();
    if (isShowReportToggle) {
      isShowToggle.value = true;
    }
  };
  const addFilter = (filter: unknown) => {
    store.dispatch(`${moduleName}/${customFieldNames?.addFilter || "addFilter"}`, filter);
  };
  const getTreeData = () => {
    store.dispatch(`${moduleName}/getTreeData`);
  };

  const getHiddenKeys = () => {
    const filtersWrap = document.getElementById("flexWrap") as HTMLElement;
    const filters: HTMLElement[] = filtersWrap ? (Array.from(filtersWrap.childNodes) as HTMLElement[]) : [];
    if (filters.length > 0) {
      const firstRowTop = getElementSlotY(filters[0]);
      notHiddenElements.value = filterHiddenFilters(filters, firstRowTop, false);
      notHiddenKeys.value = notHiddenElements.value.map((filter: HTMLElement) => filter.id);
      hiddenKeys.value = filterHiddenFilters(filters, firstRowTop, true).map((filter: HTMLElement) => filter.id);
    }
    updateFiltersDivHeight();
  };

  const filterHiddenFilters = (filters: HTMLElement[], firstRowTop: number, isHidden: boolean) => {
    return isHidden
      ? filters.filter((filter: HTMLElement) => getElementSlotY(filter) > firstRowTop + 10)
      : filters.filter((filter: HTMLElement) => getElementSlotY(filter) <= firstRowTop + 10);
  };

  const getElementSlotY = (element: HTMLElement) => {
    return (element?.querySelector?.(".v-input__slot") as HTMLElement)?.getClientRects?.()[0].y || 0;
  };

  const getHiddenKeysDebounce = debounce(function () {
    if (!props.value) {
      getHiddenKeys();
    }
  }, 50);

  const updateFiltersDivHeight = () => {
    Vue.nextTick(() => {
      const firstRowHeights = notHiddenElements.value.map((item) => item.scrollHeight);
      filtersDivHeight.value = Math.max(...firstRowHeights) + 20;
      const filtersWrap = document.getElementById("flexWrap") as HTMLElement;
      if (filtersWrap) {
        filtersWrap.scrollTo(0, 0);
      }
    });
  };

  const openHiddenFilterOnStart = () => {
    const isNeedOpen = Object.entries(currentFilters.value || {}).some(([key, value]) => {
      return hiddenKeys.value.includes(key) && !isEmpty(value);
    }, false);
    if (isNeedOpen) {
      context.emit("change", isNeedOpen);
    }
  };

  const restoreFiltersFromStore = (isDefault: boolean) => {
    currentFilters.value = {
      ...(isDefault ? setCurrentFilters() : currentFilters.value),
      ...fieldFilters.value,
    };
  };

  const clearFiltersNewCb = debounce(
    function () {
      currentFilters.value = setCurrentFilters();
      addFilter(currentFilters.value as Record<string, SORT_TYPE>);
      refreshScroll();
    },
    1000,
    { leading: true, trailing: false }
  );

  const toggleOpenFilter = () => {
    getHiddenKeys();
    context.emit("change", !props.value);
  };

  const clearFiltersCb = debounce(
    function () {
      if (!isReportForm) {
        clearFilters();
      }
      filtersDivHeight.value = defaultFiltersDivHeight;
      restoreFiltersFromStore(true);
      if (isReportForm) {
        clearReportFilters();
        return;
      }
      refreshScroll();
      if (props?.currentDocumentView === ViewMode.TREE) {
        getTreeData();
      }
      if (getQueryCb) {
        getQueryCb();
      }
    },
    1000,
    { leading: true, trailing: false }
  );

  const refresh = debounce(
    function () {
      if (isReportForm) {
        refreshReport();
        return;
      }
      addFilter(currentFilters.value);
      refreshScroll();
      if (props?.currentDocumentView === ViewMode.TREE) {
        getTreeData();
      }
      if (getQueryCb) {
        getQueryCb();
      }
    },
    1000,
    { leading: true, trailing: false }
  );

  onMounted(() => {
    filtersDivHeight.value = defaultFiltersDivHeight;
    restoreFiltersFromStore(true);
    getHiddenKeys();
    // this.openHiddenFilterOnStart();
    window.addEventListener("resize", getHiddenKeysDebounce);
  });

  onUnmounted(() => {
    window.removeEventListener("resize", getHiddenKeysDebounce);
  });

  watch(ref(route.value.query.historyId), (value: Ref<string | (string | null)[]>) => {
    Vue.nextTick(() => {
      restoreFiltersFromStore(true);
    });
  });

  return {
    filter,
    hiddenRowsStyleObj,
    openHiddenFilterOnStart,
    toggleOpenFilter,
    clearFiltersCb,
    clearFiltersNewCb,
    refresh,
    updateFiltersDivHeight,
    restoreFiltersFromStore,
  };
};

export default useFilter;
